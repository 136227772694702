label.FloorplanLabel {
  position: absolute;
  color: white;
  font-size: 1.3em;
  font-weight: bold;
  display: inline-block;
  width: 25%;
  text-align: center;
}

label.FloorplanLabel .value {
  vertical-align: top;
  display: inline-block;
}

label.FloorplanLabel .unit {
  font-size: 0.5em;
  margin-top: 0.3em;
  vertical-align: top;
  display: inline-block;
}