.Gauge {
  display: inline-block;
  color: white;
  text-align: center;
  padding-bottom: 1em;
}

.Gauge_l {
  width: 100vw;
}

.Gauge_m {
  width: 50vw;
}

.Gauge_s {
  width: 32vw;
  padding-bottom: 1.4em;
}

.Gauge .label {
  font-size: 1.1em;
  font-weight: 100;
}

.Gauge .data {
  display: inline-block;
  font-weight: 900;
  font-size: 5em;
  vertical-align: top;
}

.Gauge_l .data {
  font-size: 8em;
}

.Gauge_m .data {
  font-size: 5em;
}

.Gauge_s .data {
  font-size: 2.5em;
}

.Gauge .unit {
  display: inline-block;
  font-weight: 100;
  font-size: 1em;
  vertical-align: top;
  margin-top: 0.2em;
  margin-left: 0.1em;
}

.Gauge_l .unit {
  margin-top: 1.9em;
}