.Floorplan {
  position: relative;
  float: left;
  width: 64vw;
  height: 50vh;
  vertical-align: top;
}

.Floorplan img {
  position: absolute;
  vertical-align: top;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%
}